import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import SignupPage from "pages/Signup.js";

export default () => (
  <AnimationRevealPage>
    <Hero /> {/** First Page */}
    <MainFeature /> {/** Our Track Record */}
    <Features /> {/** Professionals Services */}
    {/* <SignupPage /> */}
    {/* <ContactUsForm /> */}
    <Footer />
  </AnimationRevealPage>
);
