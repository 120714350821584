import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Config from "Config"
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import useRouter from './useRouter';
import { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import validate from 'validate.js';

const schema = {
   email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true
   },
   firstName: {
      presence: { allowEmpty: false, message: 'is required' }
   },
   mobile: {
      presence: { allowEmpty: false, message: 'is required' }
   },
   countryCode: {
      presence: { allowEmpty: false, message: 'is required' }
   }
};

const useStyles = makeStyles((theme) => ({
   paper: {
      paddingTop: theme.spacing(10),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
   },
   results: {
      marginTop: theme.spacing(1)
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main
   },
   form: {
      width: '100%',
      marginTop: theme.spacing(0)
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: theme.palette.primary.main
   },
   container: {
      backgroundColor: '#ffffff',
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh'
   }
}));

const Login = (props) => {
   const { className, ...rest } = props;
   const router = useRouter();
   const classes = useStyles();
   const [loading, setLoading] = useState(false);
   const [loadingOtp, setLoadingOtp] = useState(0);

   const [countryList, setCountryList] = useState([])
   const [countryCallingCodeList, setCountryCallingCodeList] = useState([])
   const [selectedCountry, setSelectedCountry] = useState(0)
   const [selectedCountryCallingCode, setSelectedCountryCallingCode] = useState(0)
   const [selectedCountryCode, setSelectedCountryCode] = useState()
   const [dobList, setDobList] = useState([])
   const [dob, setDob] = useState(1997)
   const [numberOfStocks, setNumberOfStocks] = useState(2)
   const [otp, setOtp] = useState()
   const [isMobileError, setIsMobileError] = useState(false);
   const [isEmailError, setIsEmailError] = useState(false);

   const [formState, setFormState] = useState({
      isValid: false,
      values: {
         // email: "satyasandeep786@gmail.com",
         // firstName: "Sandeep",
         // lastName: "Kumar",
         // mobile: "6469575379",
      },
      touched: {},
      errors: {}
   });

   const handleChange = (event) => {
      event.persist();

      setFormState((formState) => ({
         ...formState,
         values: {
            ...formState.values,
            [event.target.name]:
               event.target.type === 'checkbox'
                  ? event.target.checked
                  : event.target.value
         },
         touched: {
            ...formState.touched,
            [event.target.name]: true
         }
      }));
   };

   const getIpAddress = async () => {
      const url = "https://api.ipify.org/?format=json";
      const res = await axios.get(url);
      return res.data.ip
   }

   const getDetailsFromIpAddress = async (ip) => {
      const res = await axios.get(`https://ipapi.co/${ip}/json`);
      return res.data
   }

   const getYearsList = () => {
      const year = moment().subtract(10, 'years').format("YYYY");
      const array = Array.from(new Array(100), (v, i) => {
         return v = year - i
      })
      setDobList(array)
   };

   useEffect(() => {
      const errors = validate(formState.values, schema);
      setFormState((formState) => ({
         ...formState,
         isValid: errors ? false : true,
         errors: errors || {}
      }));
   }, [formState.values]);

   useEffect(() => {
      const localCountries = JSON.parse(localStorage.getItem("countryList"))
      setCountryList(localCountries)
      if (!localCountries) getAllCountriesList()
      getYearsList()
      // setSelectedCountry("United States of America")
   }, [])

   const getAllCountriesList = async () => {
      // const countryList = await axios.get("https://restcountries.eu/rest/v2/all")
      const countryList = [
         {
            name: "India",
            code: "IN",
            callingCodes: ["91"],
         },
         {
            name: "United States of America",
            code: "US",
            callingCodes: ["1"],//840
         },
         {
            name: "Canada",
            code: "CA",
            callingCodes: ["1"],
         }
      ]
      localStorage.setItem("countryList", JSON.stringify(countryList))
      setCountryList(countryList)
   }

   useEffect(() => {
      if (selectedCountry) {
         const selectedCountryName = countryList.find(item => item.name === selectedCountry)
         setCountryCallingCodeList(selectedCountryName.callingCodes)
         setSelectedCountryCallingCode(selectedCountryName.callingCodes[0])
      }
   }, [countryList, selectedCountry])

   const handleChangeCountry = async (e) => {
      const countryCode = countryList.find(item => item.name === e.target.value)
      if (countryCode.code === "IN") {
         setIsEmailError(true)
         setIsMobileError(false)
      }
      if (countryCode.code !== "IN") {
         setIsEmailError(false)
         setIsMobileError(true)
      }
      setSelectedCountry(e.target.value)
      setSelectedCountryCode(countryCode.code)
   }

   const handleChangeStocks = async (e) => {
      setNumberOfStocks(e.target.value)
   }

   const handleChangeOtp = async (e) => {
      setOtp(e.target.value)
   }

   const handleChangeDob = async (e) => {
      setDob(e.target.value)
   }

   const handleChangeCountryCallingCode = async (e) => {
      setSelectedCountryCallingCode(e.target.value)
   }

   const sendVerificationOtp = async () => {
      if (!formState.values.mobile) return alert("Mobile No is required")
      await setLoadingOtp(1)
      let data = {
         "mobile": `${selectedCountryCallingCode}${formState.values.mobile}`,
         "countryCode": selectedCountryCode,
         "email": formState.values.email,
         "name": `${formState.values.firstName} ${formState.values.lastName}`
      }
      let config = {
         method: 'post',
         url: `${Config.hostName}/user/send/otp`,
         headers: {
            'Content-Type': 'application/json'
         },
         data: data
      };

      const sendData = await axios(config)
      if (sendData.data.success) {
         setLoadingOtp(2)
      };
      if (!sendData.data.success) {
         setLoadingOtp(3);
         alert(sendData.data.errors[0].message)
      }
   }

   const handleSubmit = async (event) => {
      event.preventDefault();
      if (!formState.values.email) return alert("Email is required")
      if (!formState.values.firstName) return alert("First Name is required")
      if (!formState.values.mobile) return alert("Mobile is required")
      setLoading(true);
      const ip = await getIpAddress();
      console.log(ip, "ip");
      const ipDetails = await getDetailsFromIpAddress(ip);
      const ipLocation = !ipDetails.error
         ? ` ${ipDetails.city}, ${ipDetails.region}, ${ipDetails.country_name}, ${ipDetails.postal}`
         : 'Failed to Get IP Location';
      const latLong = !ipDetails.error
         ? `${ipDetails.latitude}, ${ipDetails.longitude}`
         : 'Failed to Get Lat Long';

      let data = {
         "firstName": formState.values.firstName,
         "lastName": formState.values.lastName ? formState.values.lastName : "",
         "email": formState.values.email,
         "mobile": `${selectedCountryCallingCode}${formState.values.mobile}`,
         "countryCode": selectedCountryCode,
         "city": ipDetails.city,
         "dob": dob,
         "otp": otp,
         "noOfStocks": numberOfStocks,
         "loginDetails": [
            {
               "ip": ip,
               "latLong": latLong,
               "ipLocation": ipLocation
            }
         ]
      };

      let config = {
         method: 'post',
         url: `${Config.hostName}/user/add`,
         headers: {
            'Content-Type': 'application/json'
         },
         data: data
      };

      const sendData = await axios(config)
      if (sendData.data.success) {
         setLoading(false)
         alert("Successfully signed up")
         router.history.push('/');
      };
      if (!sendData.data.success) {
         setLoading(false);
         alert(sendData.data.errors[0].message)
      }


      // router.history.push('/management/orders');
   }

   const hasError = (field) =>
      formState.touched[field] && formState.errors[field] ? true : false;

   return (
      <div className={classes.container}>
         <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
               <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5">
                  Sign Up
               </Typography>
               <form className={classes.form} noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                     <Grid item md={12} xs={12}>
                        <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           error={isEmailError}
                           helperText={hasError('email') ? formState.errors.email[0] :
                              isEmailError ?
                                 " *Note : We will be sending an otp to your Email" : ""}
                           value={formState.values.email || ''}
                           onChange={handleChange}
                           id="email"
                           placeholder="Email Address"
                           // label="Email Address"
                           name="email"
                           autoComplete={0}
                           size="small"
                           autoFocus
                           type="text"
                        />
                     </Grid>
                  </Grid>

                  <Grid className={classes.results} container spacing={1}>
                     <Grid item md={6} xs={6}>
                        <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           error={hasError('firstName')}
                           helperText={hasError('firstName') ? formState.errors.firstName[0] : null}
                           value={formState.values.firstName || ''}
                           onChange={handleChange}
                           id="firstName"
                           placeholder="First Name"
                           // label="Email Address"
                           name="firstName"
                           autoComplete={0}
                           size="small"
                           autoFocus
                        />
                     </Grid>
                     <Grid item md={6} xs={6}>
                        <TextField
                           variant="outlined"
                           margin="normal"
                           required
                           fullWidth
                           error={hasError('lastName')}
                           helperText={hasError('lastName') ? formState.errors.lastName[0] : null}
                           value={formState.values.lastName || ''}
                           onChange={handleChange}
                           id="lastName"
                           placeholder="Last Name"
                           // label="Email Address"
                           name="lastName"
                           autoComplete={0}
                           size="small"
                           autoFocus
                        />
                     </Grid>
                  </Grid>

                  <Grid className={classes.results} container spacing={1}>
                     <Grid item md={6} xs={6}>
                        <TextField
                           fullWidth
                           name="selectedCountry"
                           onChange={handleChangeCountry}
                           select
                           size="small"
                           type="text"
                           value={selectedCountry}
                           variant="outlined"
                        >
                           <MenuItem value={0} disabled>
                              Country
                           </MenuItem>
                           {countryList.map((option) => (
                              <MenuItem key={option.name} value={option.name}>
                                 {option.name}
                              </MenuItem>
                           ))}
                        </TextField>
                     </Grid>
                     <Grid item md={6} xs={6}>
                        <TextField
                           fullWidth
                           name="selectedCountryCallingCode"
                           onChange={handleChangeCountryCallingCode}
                           select
                           size="small"
                           type="text"
                           // disabled={!countryCallingCodeList.length > 0}
                           value={selectedCountryCallingCode ? selectedCountryCallingCode : ""}
                           variant="outlined">
                           <MenuItem value={0} disabled>
                              Calling Code
                           </MenuItem>
                           {countryCallingCodeList.map((option) => (
                              <MenuItem key={option} value={option}>
                                 {option}
                              </MenuItem>
                           ))}
                        </TextField>
                     </Grid>
                  </Grid>

                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     error={isMobileError}
                     helperText={hasError('mobile') ? formState.errors.mobile[0] :
                        isMobileError ?
                           " *Note : We will be sending an otp to your Mobile" : ""}
                     value={formState.values.mobile || ''}
                     onChange={handleChange}
                     id="mobile"
                     placeholder="Mobile"
                     // label="Email Address"
                     name="mobile"
                     autoComplete={0}
                     size="small"
                     autoFocus
                     type="number"
                  />

                  <Grid className={classes.results} container spacing={1}>

                     <Grid item md={6} xs={6}>
                        <TextField
                           fullWidth
                           name="dob"
                           onChange={handleChangeDob}
                           select
                           size="small"
                           type="text"
                           disabled={!dobList.length > 0}
                           value={dob}
                           variant="outlined">
                           <MenuItem value={0} disabled>
                              Year of Birth
                           </MenuItem>
                           {dobList.map((option) => (
                              <MenuItem key={option} value={option}>
                                 {option}
                              </MenuItem>
                           ))}
                        </TextField>
                     </Grid>

                     <Grid item md={6} xs={6}>
                        <TextField
                           fullWidth
                           name="numberOfStocks"
                           placeholder="Number Of Stocks"
                           onChange={handleChangeStocks}
                           size="small"
                           type="number"
                           value={numberOfStocks}
                           variant="outlined">
                        </TextField>
                     </Grid>

                  </Grid>

                  <Grid className={classes.results} container spacing={1}>

                     <Grid item md={6} xs={6}>

                        <Button
                           color="secondary"
                           disabled={loadingOtp !== 0}
                           fullWidth
                           onClick={sendVerificationOtp}
                           size="medium"
                           type="submit"
                           variant="contained"
                        >
                           <span>
                              {loadingOtp === 0 ? "Send Otp" :
                                 loadingOtp === 1 ? "Sending OTP" :
                                    loadingOtp === 2 ? "OTP Sent" :
                                       "Something Went Wrong"
                              }
                           </span>
                        </Button>
                     </Grid>

                     <Grid item md={6} xs={6}>
                        <TextField
                           fullWidth
                           name="otp"
                           placeholder="OTP"
                           onChange={handleChangeOtp}
                           size="small"
                           type="number"
                           value={otp}
                           variant="outlined">
                        </TextField>
                     </Grid>
                  </Grid>

                  <Button
                     className={classes.submit}
                     color="secondary"
                     disabled={loading || loadingOtp !== 2}
                     fullWidth
                     size="medium"
                     type="submit"
                     variant="contained"
                  >
                     {loading ? (
                        <span>
                           <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: '5px' }}
                           />{' '}
                           Signing Up{' '}
                        </span>
                     ) : (
                        <span>
                           <i className="fa fa-sign-in" style={{ marginRight: '5px' }} />{' '}
                           Sign Up{' '}
                        </span>
                     )}
                  </Button>

               </form>
            </div>
            <Box mt={8}>{/* <Copyright /> */}</Box>
         </Container>
      </div>
   );
};

export default Login;
