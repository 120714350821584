/* eslint-disable no-unused-vars */
const production = {
   hostName: '',
};

const localhost = {
   hostName: 'http://localhost:7777',
};

const staging = {
   hostName: 'http://35.224.62.12:7777',
};
//testing gitHub

const config = staging;

export default {
   ...config
};
